$primary: #fd7e14;
$secondary: #6610f2;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// @media (min-width: 1200px) {
//   .container{
//     max-width: 720px !important;
//   }
// }

@import "../node_modules/bootstrap/scss/bootstrap";